<template>
    <BaseSection class="section--wide">
        <template #title>
            <h2 :id="kebabCase(title)">{{ title }}</h2>
        </template>

        <template
            v-if="subtitle"
            #subtitle
        >
            <p>{{ subtitle ?? '' }}</p>
        </template>

        <template #action>
            <nuxt-link
                to="/news"
                aria-label="See all news"
            >
                See all <BaseIcon icon="chevron-right" />
            </nuxt-link>
        </template>
        <BaseCardCollection :cards="cards" />
    </BaseSection>
</template>

<script setup lang="ts">
import {kebabCase} from 'lodash-es';
import RelatedNewsQuery from '~/graphql/queries/news/Related.graphql';
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();

type ArticleImage = {
    large: string;
    medium: string;
    small: string;
};

type Article = {
    id: string;
    title: string;
    postDate: string;
    image: ArticleImage[];
    uri: string;
};
type Props = {
    title: string;
    subtitle?: string;
    tags: Array<{ id: string; name: string }>;
    news: Article[];
};

const props = defineProps<Props>();

const cardLimit = computed(() => useRoute().name === 'related-content' ? 4 : 3);

const filledNews = ref<Article[]>([]);

watch([props.tags, props.news], async function() {
    const destructed = cardLimit.value;
    filledNews.value = props.news || [];

    // Exclude news items already added manually
    const excludeIds = props.news ? props.news.map(({ id }) => id) : [];

    // Check if the new news articles need to be related by tags
    const tagIds = computed(() => props.tags.map(({ id }) => id) || null);

    let variables = {};
    let query = RelatedNewsQuery;

    // If less than 3 or 4 news articles were passed, collect the rest
    if (useRoute().name === 'topics-slug' && (!props.news || props.news.length < cardLimit.value)) {
        const limit = props.news.length ? cardLimit.value - props.news.length : cardLimit.value;

        variables = {
            limit,
            exclude: ['not', ...excludeIds],
            relatedToEntries: [
                {
                    slug: useRoute().params.slug,
                    type: 'topic'
                }
            ]
        };

        // note: query = RelatedNewsQuery
    }
    if(!filledNews.value) {
        return;
    }
    // If less than 3 or 4 news articles found, collect the rest
    if (filledNews.value.length < destructed) {
        // Check how many more news articles need to be queried
        const limit = filledNews.value.length ? destructed - filledNews.value.length : destructed;

        variables = {
            limit,
            exclude: ['not', ...excludeIds],
            tagIds: tagIds.value
        };

        query = RelatedNewsQuery;
    }

    const { data } = await useAsyncQuery({
        query,
        variables
    });

    if (data.value && data.value.news) {
        filledNews.value = filledNews.value.concat(data.value.news);
    }
}, { immediate: true });


const { formatSingleDate, formats } = useDate();
const { getCardPills } = usePillsCard();

const cards = computed(() => {
    if (filledNews.value && Array.isArray(filledNews.value)) {
        return filledNews.value.map((news) => {
            let image = null;
            if (news.image.length) {
                image = {
                    src: news.image?.[0]?.large,
                    srcset: getSrcSet(news.image?.[0])
                };
            }
            return {
                image,
                title: news.title,
                meta: formatSingleDate(news.postDate, formats.dayMonthYear),
                pills: getCardPills(news),
                uri: news.uri
            };
        });
    }
    return [];
});
</script>
