<template>
    <BaseSection class="cards section--wide section--extra-spacing">
        <h2 class="cards__title">{{ title }}</h2>

        <div
            v-if="!isLoading && linksList.length"
            class="cards__card"
        >
            <BaseGuidelineCard
                v-for="(card, index) in linksList"
                :key="index"
                :url="
                    card.linkExternal
                        ? card.linkExternal
                        : '/' + card.linkInternal[0].uri
                "
            >
                {{ card.text }}
            </BaseGuidelineCard>
        </div>
    </BaseSection>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        linksList: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
};
</script>

<style lang="less">
.cards {
    .flexible-sections {
        display: none;
    }
}

.cards__card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.125rem;

    @media @q-md-min {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @media @q-lg-min {
        grid-template-columns: repeat(4, 1fr);
    }
}

.cards__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @media @q-md-min {
        font-size: 2rem;
    }
}
</style>
